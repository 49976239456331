<script setup>
  const props = defineProps({
    content: { type: String },
    size: { type: String, default: 'sm' }
  })

  const sizes = {
    xs: 'text-xs',
    sm: 'text-sm',
    base: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
    '2xl': 'text-xl',
    '3xl': 'text-xl',
    '4xl': 'text-xl',
    '5xl': 'text-xl',
    '6xl': 'text-xl',
    '7xl': 'text-xl',
    '8xl': 'text-xl',
    '9xl': 'text-xl'
  }
</script>

<template>
  <ClientOnly>
    <Popper hover offsetDistance="4">
      <slot name="default" />
      <template #content>
        <div :class="['rounded bg-zinc-300 px-2 py-1 text-center text-tarawera-900 shadow', sizes[props.size]]">
          <template v-if="content">{{ content }}</template>
          <slot v-else name="content" />
        </div>
      </template>
    </Popper>

    <template #fallback>
      <div :title="content">
        <slot name="default" />
      </div>
    </template>
  </ClientOnly>
</template>
